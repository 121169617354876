import { Injectable } from "@angular/core";
import { IConfig } from "core/config";
import { IConfigMap } from "./configMap";
import { devConfig } from "./devConfig.map";
import { demoConfig } from "./demoConfig.map";
import { getWindow } from "./getWindow";
import { prodConfig } from "./prodConfig.map";
import { qaConfig } from "./qaConfig.map";
import { tempDevConfig } from "./tempDevConfig.map";
import { localConfig } from "./localConfig.map";
import { kubernetesConfig } from "./kubernetesConfig.map";
import { UserAutoFocusoutTime } from "core/constants";

export function staticConfig<T extends IConfig>(constructor: T) {
  return constructor;
}

@Injectable()
@staticConfig
export class Config {
  private static get environmentConfig(): IConfigMap {
    const devUrl = "dev.modeler2.decisionsfirst.com";
    const demoUrl = "demo.modeler2.decisionsfirst.com";
    const prodUrl = "modeler2.decisionsfirst.com";
    const qaUrl = "qa.modeler2.decisionsfirst.com";
    const tempDevUrl = "temp-dev.modeler2.decisionsfirst.com";
    const kubernetesUrl = "localhost:8070";

    switch (getWindow().location.hostname) {
      case devUrl:
        return devConfig;
      case demoUrl:
        return demoConfig;
      case prodUrl:
        return prodConfig;
      case qaUrl:
        return qaConfig;
      case tempDevUrl:
        return tempDevConfig;
      case kubernetesUrl:
        return kubernetesConfig;
      default:
        return devConfig;
    }
  }

  public static get proxyUri(): string {
    return this.environmentConfig.proxyUri;
  }

  public static get rootUri(): string {
    return this.environmentConfig.rootUri;
  }

  public static get authBaseUrl(): string {
    return this.environmentConfig.authBaseUrl;
  }

  public static get environment(): string {
    return this.environmentConfig.environment;
  }

  public static get identityManagement(): string {
    return this.environmentConfig.identityManagement;
  }

  public static get defaultLanguageCode(): string {
    return "en";
  }

  public static get pageSize(): number {
    return 10;
  }

  public static get debounceTime(): number {
    return 2000;
  }

  public static get searchDebounceTime(): number {
    return 250;
  }

  public static get wijmoLicenseKey(): string {
    return this.environmentConfig.wijmoLicenseKey;
  }

  public static get goJsLicenseKey(): string {
    return this.environmentConfig.goJsLicenseKey;
  }

  public static get autocompleteListPageSize(): number {
    return 3;
  }

  public static get environmentName(): string {
    return this.environmentConfig.name;
  }

  public static get timeControlDebounceTime(): number {
    return 200;
  }

  public static get userAutoFocusoutTime(): number {
    return UserAutoFocusoutTime;
  }

  public static get simulationUrl(): string {
    return this.environmentConfig.simulationUri;
  }
}
