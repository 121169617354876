import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';

@Injectable()
export class ExternalConfigurationService {
  deserialize() {
    throw new Error('Method not implemented.');
  }
  serialize() {
    throw new Error('Method not implemented.');
  }
  getProxyUri(): string {
    return Config.proxyUri;
  }

  getRootUri(): string {
    return Config.rootUri;
  }

  getHttp(): HttpClient {
    return this.http;
  }

  getIdentityManagement(): string {
    return Config.identityManagement;
  }

  getAuthBaseUrl(): string {
    return Config.authBaseUrl;
  }

  constructor(private http: HttpClient) { }

  getExternalConfiguration() {
    return Config;
  }

  setExternalConfiguration(externalConfiguration) { }

  
  getSimulationUri() {
    return Config.simulationUrl;
  }
}
