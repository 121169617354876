import { EnvName, IdentityManagement } from './config.model';
import { IConfigMap } from './configMap';

export const devConfig: IConfigMap = {
  environment: EnvName.DEV,
  name: EnvName.DEV,
  proxyUri: '',
  rootUri: 'https://beta.api.decisionsfirst.com/',
  authBaseUrl: 'https://beta.api.decisionsfirst.com/authentication/',
  identityManagement: IdentityManagement.INAPP,
  wijmoLicenseKey: 'Decision Management Solutions,477557366634626#B07eYICbuFkI1pjIEJCLi4TPBl7LwYnMVtCc0ZzULd7SQpXM4oEcxMmdzBnRyFnN4VnTG5kR4YjM5hzRF3GUJpFZM36cUdHNpB5b0dld8k4NysCcZVkZypnbjF7a6l7NBVVeulDVw2iSwFmSFFUWxUjSqZ6Y8B5ZOtEbCxmb5FEexZ5U6lmNGZUUZlkW8RTdXVGZSlnWUZ4Y6pVbO5UNal6cwI5K8kDNDdTVIlkeEhlMn54KltSOUhHONRXTphEM8JzKyATOEVlbrZzKUlzS8R5MaljQDBXc6ZUTkBHM0N4T624RrsyNOZWSQN4ZpxkRroVMypWdYd4QTBHakllUsNGO4YjRPd4dxRWUxhzawpGTuBlT7p6KvZUM5olR4N6Zw46L8s6awwmZGVEUSJkZ8YUSLpGe0JUdvM6S6gzMWlnNwhHWjdmN6hEeVV6dapGNRRWQ64mVWxmNGFTSuNWNMlzaxIFSIBHaiojITJCLiIzQDdzQGFzMiojIIJCL5IDM4UjNxETO0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsICOzATMyEDI6IzMwUjMwIjI0ICdyNkIsIycu3Wa4VHbvNFI49WZtV6Zh9WYNBibvl6cpNWZEJiOiEmTDJCLiYjM6QzM6YjNzcTN5czN4IiOiQWSiwSfdtlOicGbmJCLiEjd5IDMyIiOiIXZ6JCLlNHbhZmOiIKc6J',
  goJsLicenseKey: '73f942e1b46128a800ca0d2b113f69ee1bb37b679ed11ef45e0042f5ef5f68112bc9ec7958868fc7d5f84ffa1c28c6db8ecd6d2fc01b172be06383dc43b0d6fee03571b1145c4e88a1532eca9dfd2cf3fc2876f7c0e724a7de70dbf6e8a0c4cb54b8a6874c995db82d7a0764527efc4ae4ffdd7cfb04c8486938caf4a9eeac4ce87c71d1cee257dabf5863cebceca35d697208d6',
  internetConnectivityTestUrl: 'https://dev.images.decisionsfirst.com/images/imagesdfm.jpg',
  simulationUri: 'https://dev.testing.decisionsfirst.com/',
};

